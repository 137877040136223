import './PageNotFound.css';
import '../../../App.css';

function PageNotFound() {
    return (
        <div className="notfound">
          <div>We couldn't find your page.</div>
        </div>
    );
}

export default PageNotFound;